// Resources Page Styles

// Header override for resources page
.header-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: transparent;

    // Style for the dark header
    &.navbar-dark {
        .navbar-nav .nav-link {
            color: rgba(255, 255, 255, 0.85);
            &:hover {
                color: rgba(255, 255, 255, 1);
            }
        }

        .navbar-brand-item {
            filter: brightness(0) invert(1);
        }
    }
}

// Hero Section
.hero-section {
    background-color: $dark-blue;
    padding: 8rem 0 4rem;
    margin: 0;
    
    .text-orange {
        color: #ff7a5c;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .hero-title {
        font-size: 5rem;
        color: #fff;
        line-height: 1.1;
        margin-bottom: 2rem;
        
        .underline {
            position: relative;
            display: inline-block;
            padding: 0 0.1rem;
            font-style: italic;
            
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -0.5rem;
                width: 100%;
                height: 0.4rem;
                background-color: #ff7a5c;
                transform-origin: left;
                animation: underlineSlide 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
                z-index: 0;
            }
        }
        
        @media (max-width: 768px) {
            font-size: 3rem;
        }
    }

    .hero-subtitle {
        color: rgba(255, 255, 255, 0.9);
        font-size: 1.25rem;
        line-height: 1.6;
        margin-bottom: 0;
    }
}

// Add keyframes for the animation
@keyframes underlineSlide {
    0% {
        transform: scaleX(0);
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
        opacity: 1;
    }
}

// Optional hover effect
.hero-title .underline:hover::after {
    animation: underlinePulse 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

@keyframes underlinePulse {
    0%, 100% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(1.05);
    }
}

// White space divider
.bg-white {
    background-color: $dark-blue;
}

// Divider - make it exact
.divider {
    background-color: #fff;
    height: 0.4px;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
}

// Featured Post Section
.featured-post {
    background-color: $dark-blue;
    margin: 0;
    padding: 2rem 0;

    .featured-title {
        font-size: 1.75rem;
        color: #fff;
        margin-bottom: 1.5rem;
        font-weight: 500;
    }

    .card {
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

        .row {
            min-height: 400px;
        }

        .card-body {
            padding: 1.5rem !important;
            height: 100%;
            
            h3 {
                font-size: 1.75rem;
                margin-bottom: 1rem;
            }
            
            p {
                font-size: 1.1rem;
                line-height: 1.6;
            }
        }

        .badge {
            padding: 0.35rem 0.75rem;
            font-weight: 500;
            border-radius: 2rem;
            letter-spacing: 0.5px;
            
            &.bg-navy {
                background-color: $dark-blue !important;
            }
            
            &.bg-coral {
                background-color: #ff7a5c !important;
            }
            
            &.bg-purple {
                background-color: #6b46c1 !important;
            }
        }

        .card-title {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.3;
            color: $dark-blue;
            margin-bottom: 0.75rem;
        }

        .card-text {
            color: #4a5568;
            line-height: 1.5;
            font-size: 1rem;
            margin-bottom: 1rem;
        }

        .btn-outline-primary {
            border-color: #ff7a5c;
            color: #ff7a5c;
            
            &:hover {
                background-color: #ff7a5c;
                color: #fff;
            }
        }

        .row {
            > [class^="col-"] {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .featured-image-container {
        height: 100%;
        aspect-ratio: 4/3;
        overflow: hidden;
        
        @media (max-width: 991px) {
            aspect-ratio: 16/9;
        }
        
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    @media (max-width: 991px) {
        .row {
            min-height: auto !important;
        }
        
        .featured-image-container {
            height: 300px;
            width: 100%;
            aspect-ratio: 16/9;
        }
    }
}

// Resources Grid
.resources-grid {
    padding: 4rem 0;

    .resources-title {
        font-size: 2.5rem;
        color: #1a1f36;
        margin-bottom: 2rem;
        font-weight: 600;
    }
}

// Utility classes
.bg-dark-blue {
    background-color: $dark-blue;
} 

.resource-card {
    margin-bottom: 2rem;
    text-decoration: none;
    display: block;
    
    .card-image {
        margin-bottom: 1rem;
        border-radius: 0.75rem;
        overflow: hidden;
        aspect-ratio: 16/9;
        
        .cover-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-title {
        font-size: 1.5rem;
        color: #1a1f36;
        margin-bottom: 0.75rem;
        font-weight: 600;
        line-height: 1.2;
    }

    .card-description {
        color: #64748b;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    .card-meta {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #64748b;
        font-size: 0.875rem;

        .date {
            &:after {
                content: "•";
                margin-left: 0.5rem;
            }
        }

        .author {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
}

.resources-filters {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    .search-input {
        background: #F9FAFB;
        border: 1px solid #E5E7EB;
        border-radius: 0.5rem;
        padding: 0.75rem 1rem;
        width: 100%;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        color: var(--bs-primary);
        
        &::placeholder {
            color: #6B7280;
        }
    }

    .filter-group {
        margin-bottom: 1rem;
        
        label {
            display: block;
            margin-bottom: 0.5rem;
            color: var(--bs-primary);
            font-weight: 500;
        }
        
        select {
            width: 100%;
            padding: 0.75rem 1rem;
            border: 1px solid #E5E7EB;
            border-radius: 0.5rem;
            background-color: #F9FAFB;
            color: var(--bs-primary);
            cursor: pointer;
            
            &:focus {
                outline: none;
                border-color: var(--bs-primary);
            }
        }
    }

    .clear-filters {
        display: inline-block;
        margin-top: 1rem;
        color: #FF4F4F;
        text-decoration: none;
        font-size: 0.875rem;
        
        &:hover {
            text-decoration: underline;
        }
    }
}

// Add these new styles at the end of your existing file
.featured-image-container {
    width: 100%;
    height: 300px; // Adjust this value as needed
    overflow: hidden;
    
    .featured-cover-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.featured-author-image-container {
    width: 40px; // Adjust this value as needed
    height: 40px; // Adjust this value as needed
    overflow: hidden;
    
    .featured-author-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}