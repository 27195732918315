#index {
  .bloc {
      border-radius: 16px;
  }

  .work-bloc {
    border-radius: 16px;
    font-size: 15px;
    overflow: hidden;
    position: relative;
    background: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

    &.work-red-bloc {
      border: 2px solid rgba(234, 88, 88, 0.5);  // Subtle red border
      
      .visual-content {
        background: linear-gradient(to bottom, #ffffff, #fff1f1);
        border-bottom: 1px solid rgba(197, 7, 7, 0.1);
      }
    }

    &.work-blue-bloc {
      border: 2px solid rgba(59, 130, 246, 0.5);  // Subtle blue border
      
      .visual-content {
        background: linear-gradient(to bottom, #ffffff, #f0f7ff);
        border-bottom: 1px solid rgba(9, 78, 187, 0.1);
      }
    }

    .visual-content {
      position: relative;
      padding: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 320px;
      
      @media (max-width: 768px) {
        height: 260px;  // Shorter on mobile
        padding: 1.5rem;  // Less padding
      }

      @media (max-width: 576px) {
        height: 220px;  // Even shorter on very small screens
        padding: 1rem;  // Minimal padding
      }
      
      img {
        max-width: 100%;
        max-height: 320px;
        width: auto;
        height: auto;
        object-fit: contain;

        @media (max-width: 768px) {
          max-height: 220px;  // Smaller images on mobile
        }

        @media (max-width: 576px) {
          max-height: 180px;  // Even smaller on very small screens
        }
      }
    }

    .text-content {
      padding: 2rem;
      background: white;

      @media (max-width: 768px) {
        padding: 1.5rem;  // Less padding on mobile
      }

      @media (max-width: 576px) {
        padding: 1rem;  // Minimal padding on very small screens
      }
    }

    &.border-sm {
      border-width: 1px;
    }

    // Responsive padding
    @media (max-width: 768px) {
      padding: 15px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  // Responsive headings
  h1 {
    @media (max-width: 1200px) {
      font-size: 56px;
      line-height: 1.2;
    }
    @media (max-width: 768px) {
      font-size: 40px;
      line-height: 1.3;
    }
  }

  // Responsive lead text
  .lead {
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  // Responsive spacing
  .py-8 {
    @media (max-width: 768px) {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
  }

  // Responsive images
  img {
    max-width: 100%;
    height: auto;
  }

  // Responsive iframe
  iframe {
    @media (max-width: 768px) {
      height: 300px;
    }
  }

  // Bubble icon responsive
  .bubble-mask {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: $blue;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }

  // Broken text animation
  .broken-text {
    display: inline-block;
    transform: skew(-10deg);
    position: relative;
    color: $fade-1;
    animation: glitch 4s infinite;

    @media (max-width: 768px) {
      font-size: 0.9em;
    }

    &::before {
      content: attr(data-text);
      position: absolute;
      top: 3px;
      left: 3px;
      color: rgba($fade-2, 0.3);
      clip-path: polygon(0 0, 100% 0, 100% 40%, 0 45%);
      animation: glitch-top 4s infinite linear alternate-reverse;
    }

    &::after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      color: inherit;
      clip-path: polygon(0 60%, 100% 55%, 100% 100%, 0 100%);
      transform: translateX(-3px);
      animation: glitch-bottom 4s infinite linear alternate-reverse;
    }
  }

  // Responsive buttons
  .btn {
    @media (max-width: 768px) {
      padding: 10px 15px;
      font-size: 14px;
    }
  }

  // Responsive grid items
  .row > [class*='col-'] {
    @media (max-width: 768px) {
      margin-bottom: 2rem;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // Original animations
  @keyframes glitch {
    0%, 100% { transform: skew(-10deg); }
    20% { transform: skew(-10deg) translateX(-2px); }
    40% { transform: skew(-10deg) translateX(2px); }
    60% { transform: skew(-12deg) translateX(-2px); }
    80% { transform: skew(-8deg) translateX(2px); }
  }

  @keyframes glitch-top {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(-2px); }
    75% { transform: translateX(2px); clip-path: polygon(0 0, 100% 0, 100% 35%, 0 40%); }
  }

  @keyframes glitch-bottom {
    0%, 100% { transform: translateX(-3px); }
    50% { transform: translateX(-5px); }
    75% { transform: translateX(-1px); clip-path: polygon(0 65%, 100% 60%, 100% 100%, 0 100%); }
  }

  // Features section responsive styles
  .features-grid {
    @media (max-width: 991px) {
      .work-bloc {
        flex-basis: 100% !important; // Make cards full width on mobile
        margin-bottom: 1rem;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      .work-bloc {
        flex-basis: 48% !important; // Two columns on medium screens
      }
    }

    .feature-card {
      opacity: 0;  // Start hidden
      transform: translateY(20px);  // Start slightly below
      
      &.animate {
        animation: fadeInUp 0.6s ease forwards;
      }

      // Stagger the animations
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.1}s;
        }
      }
    }
  }

  // Animate header elements too
  .features-header {
    opacity: 0;
    transform: translateY(20px);
    
    &.animate {
      animation: fadeInUp 0.6s ease forwards;
    }
  }

  // Feature card responsive styles
  .feature-card {
    height: 100%;
    
    .feature-image {
      @media (max-width: 768px) {
        height: 200px; // Fixed height for images on mobile
        object-fit: cover;
      }
    }

    .feature-content {
      @media (max-width: 768px) {
        padding: 1.5rem !important;
      }

      h5 {
        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 1.4;
        }
      }

      p {
        @media (max-width: 768px) {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }

  // Add testimonial responsive styles
  .testimonial-wrapper {
    width: 46%;
    margin: 2rem auto 0;
    
    @media (max-width: 991px) {
      width: 75%;
    }
    
    @media (max-width: 768px) {
      width: 100%;
      margin: 1.5rem auto 0;
      
      img {
        width: 100% !important; // Override inline style
        height: auto;
        margin: 0 auto;
        display: block;
      }
    }
  }

  .feature-bottom-wrapper {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }

    .feature-card {
      flex: 1;
      max-width: 48.5%;

      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        .feature-content {
          padding: 1rem !important;

          h5 {
            font-size: 1.25rem;
            line-height: 1.4;
          }

          p {
            font-size: 0.9rem;
            margin-bottom: 0;
          }
        }

        .feature-image {
          height: auto;
          max-height: 200px;
          object-fit: cover;
        }
      }
    }
  }

  // Testimonial animations
  @keyframes slideInFromRight {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes glowText {
    0%, 100% {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    }
    50% {
      text-shadow: 0 0 20px rgba(255, 255, 255, 0.4);
    }
  }

  .partner-section {
    .partner-title {
      font-size: 2.8rem;  // Slightly bigger
      font-weight: 700;
      color: white;
      position: relative;
      display: inline-block;
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, $linear1-light, $linear2-light);  // Using existing brand colors
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease;
      }
      
      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    .partner-cta {
      background: linear-gradient(45deg, $linear1-light, $linear2-light);  // Using existing brand colors
      border: none;
      font-weight: 600 !important;  // fw-semibold
      transition: all 0.3s ease;
    
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 15px rgba($linear1-light, 0.3);
        filter: brightness(1.1);  // Makes the button slightly brighter on hover
      }

      &:active {
        transform: translateY(0);  // Subtle press effect
      }
    }

    .testimonial-card {
      opacity: 0;
      transform: translateX(30px);
      
      &.animate {
        animation: slideInFromRight 0.8s ease forwards;
      }
      
      // Stagger the animations
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.2}s;
        }
      }
    }
  }

  // Features section
  .bg-grey {
    margin: 0;  // Remove any margin
    padding: 6rem 0;  // Add consistent padding
  }

  // Is your tracking costing section
  .bg-primary {
    margin: 0;  // Remove margin
    padding: 6rem 0;  // Add consistent padding
  }

  // Make sections touch each other
  section {
    margin: 0;  // Remove any default margins
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .bg-grey,
    .bg-primary {
      padding: 4rem 0;  // Slightly less padding on mobile
    }
  }
}

.bg-grey-blue {
    position: relative;
    margin-top: -90px;
    padding-top: 90px;
    
    .hero-content-wrapper {
        position: relative;
        width: 100%;
        min-height: 100vh;
        
        #hero-animation {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            pointer-events: none;
        }

        .container {
            position: relative;
            z-index: 2;
        }
    }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}