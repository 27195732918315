header {
  .navbar.navbar-expand-xl {
    --bs-navbar-color: #000;
    --bs-navbar-hover-color: #000;
    --bs-navbar-active-color: #000;

     & > .container {
      max-width: 1140px !important;

      .nav-link {
        padding-right: 0;
        padding-left: 0;
        position: relative;
        font-size: 16px;

        &:before {
          height: 2px;
          background: #000;
          content: "";
          position: absolute;
          bottom: 0;
          border-radius: 8px;
          width: 0;
          transition: .4s;
        }

        &:hover, &.active {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}

.header-sticky {
  transition: all 0.3s ease-in-out;
  
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.35s ease-out;
    z-index: 1000;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}