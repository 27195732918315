body {
  font-family: 'Inter', sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

h1, h3, h4 {
  color: #1a1a1a;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

h3 {
  font-size: 1.75em;
  margin-bottom: 0.5em;
}

h4 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

/* Terms page specific styles */
.terms-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  h2 {
    color: #1a1a1a;
    margin-bottom: 10px;
  }

  p {
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}

.sidebar {
  width: 250px;
  background-color: #fff;
  border-right: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
}

.sidebar-list li {
  margin-bottom: 10px;
}

.sidebar-list a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s;
}

.sidebar-list a:hover {
  color: #0056b3;
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Footer-specific styles */
.bg-dark-blue {
    background-color: #1C2632;
  }
  
  .content {
    h4 {
      color: $fade-1;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
    }
  
    p {
      color: $gray-600;
      font-size: 1rem;
      line-height: 1.6;
    }
  
    strong {
      color: $fade-1;
    }
  }

/* Footer overrides to ensure original styling */
footer {
  h4, h5 {
    color: #fff !important; /* Using !important to ensure footer headings stay white */
  }
  
  .text-white {
    color: #fff !important;
  }
}

.footer {
  background-color: #1C2632;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Main header */
.main-header {
    padding: 16px 0;
    background: #fff;
    border-bottom: 1px solid #dadce0;
}

.brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    
    img {
        height: 24px;
        width: auto;
    }
}

.header-title {
    color: #5f6368;
    font-size: 22px;
    font-weight: normal;
    padding-left: 16px;
    margin: 0 0 0 16px;
    border-left: 1px solid #dadce0;
}

/* Navigation tabs */
.terms-header {
    background: #fff;
    border-bottom: 1px solid #dadce0;
    position: sticky;
    top: 0;
    z-index: 100;
}

.terms-nav {
    display: flex;
    gap: 32px;
    padding: 0;
    height: 48px;
    align-items: center;
    
    a {
        color: #5f6368;
        text-decoration: none;
        padding: 12px 0;
        font-size: 14px;
        position: relative;
        
        &.active {
            color: #1a73e8;
            font-weight: 500;
            
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 3px;
                background: #1a73e8;
            }
        }
        
        &:hover {
            color: #202124;
        }
    }
}

/* Layout */
.terms-layout {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}

/* Sidebar */
.terms-sidebar {
  width: 280px;
  padding: 0 8px 0 24px;
  position: fixed;
  left: 0;
  top: 120px; /* Adjust based on your header height */
  height: calc(100vh - 120px);
  overflow-y: auto;
  border-right: 1px solid #dadce0;
  
  /* Webkit scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li {
      margin: 0;
      padding: 0;
      
      a {
        display: block;
        padding: 8px 16px;
        margin: 4px 0;
        color: #5f6368;
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        border-radius: 0 20px 20px 0;
        transition: background-color 0.2s, color 0.2s;
        
        &:hover {
          background-color: #f1f3f4;
          color: #202124;
        }
        
        &.active {
          background-color: #e8f0fe;
          color: #1a73e8;
          font-weight: 500;
        }
      }
    }
  }
}

/* Main content */
.terms-content {
  margin-left: 280px;
  padding: 24px 40px;
  flex: 1;
  max-width: 800px;
  
  .content-header {
    margin-bottom: 40px;
    
    h1 {
      font-size: 24px;
      font-weight: 400;
      color: #202124;
      margin-bottom: 8px;
    }
  }
  
  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #202124;
    margin: 48px 0 16px;
  }
  
  h5 {
    font-size: 16px;
    font-weight: 500;
    color: #202124;
    margin: 24px 0 12px;
  }
  
  p {
    color: #3c4043;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  
  ul {
    padding-left: 24px;
    margin-bottom: 16px;
    
    li {
      color: #3c4043;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-layout {
    flex-direction: column;
  }
  
  .terms-sidebar {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0;
    top: 0;
    border-right: none;
    border-bottom: 1px solid #dadce0;
    margin-bottom: 24px;
    
    nav ul li a {
      border-radius: 20px;
      margin: 4px 16px;
    }
  }
  
  .terms-content {
    margin-left: 0;
    padding: 0 16px;
  }
}

/* Header buttons specific to terms pages */
.main-header {
  .btn {
    padding: 6px 16px;  /* Reduced height */
    font-size: 14px;    /* Smaller font size */
    min-height: 32px;   /* Specific height */
    
    &.btn-linear {
      background: linear-gradient(95.83deg, #0066FF -19.2%, #5B7FFF 109.05%);
      color: #fff;
      border: none;
      
      &:hover {
        background: linear-gradient(95.83deg, #0052CC -19.2%, #4B6CE6 109.05%);
      }
    }
    
    &.btn-outline-light {
      border-color: #0066FF;
      color: #0066FF;
      
      &:hover {
        background-color: rgba(0, 102, 255, 0.04);
      }
    }
  }
}

/* Legal header styles */
.main-header {
    background: #fff;
    border-bottom: 1px solid #e5e7eb;
    
    .container {
        max-width: 1200px;
        margin: 0 auto;
    }
    
    .header-title {
        color: #111928;
        font-weight: 500;
        margin-left: 0.5rem;
        
        @media (max-width: 768px) {
            font-size: 1.25rem !important;
        }
    }
}

/* Navigation menu styles */
.terms-nav-wrapper {
    background: #fff;
    border-bottom: 1px solid #e5e7eb;
    margin-top: -1px; // To prevent double border with header
}

.terms-nav {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    
    // Hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    .nav-link {
        padding: 1rem 1.5rem;
        color: #6B7280;
        text-decoration: none;
        white-space: nowrap;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease;
        
        &:hover {
            color: var(--bs-primary);
        }
        
        &.active {
            color: var(--bs-primary);
            border-bottom-color: var(--bs-primary);
        }
        
        @media (max-width: 768px) {
            padding: 0.75rem 1rem;
            font-size: 14px;
        }
    }
    
    @media (max-width: 768px) {
        padding: 0 1rem;
    }
}