#pricing {
  .free-trial {
    padding: 12px 5px !important;
    
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 8px 5px !important;
    }
  }

  .most-popular {
    background-color: #ebf5ff;
    background-image: url(/assets/images/column-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-color: #13213b !important;

    .indication {
      position: absolute;
      width: calc(100% + 4px);
      top: -38px;
      right: -2px;
      text-align: center;
      height: 64px;
      background: linear-gradient(93.69deg, #4a0353 -43.11%, #031953 -43.1%, #13213b 99.55%);
      color: #fff;
      border-radius: 16px 16px 0 0;
      font-size: 12px;
      z-index: -1;
      padding: 10px;
      
      @media (max-width: 768px) {
        height: 48px;
        top: -28px;
        font-size: 11px;
        padding: 8px;
      }
    }
  }

  // Pricing cards responsive styles
  .swiper {
    @media (max-width: 768px) {
      padding: 0 1rem;
    }
  }

  .card {
    @media (max-width: 768px) {
      margin-bottom: 1rem;
      
      .card-header {
        padding: 1.25rem;
      }
      
      .card-body {
        padding: 1.25rem;
      }
      
      h6 {
        font-size: 1rem;
      }
    }
  }

  // Pricing table responsive styles
  .table-responsive-xl {
    @media (max-width: 991px) {
      padding: 0 !important;
    }
  }

  .table {
    @media (max-width: 768px) {
      font-size: 14px;
    }
    
    tbody {
      tr {
        border-radius: 8px;

        &:nth-child(odd) {
          background-color: $light-grey;
        }
        
        @media (max-width: 768px) {
          th, td {
            padding: 0.75rem;
          }
        }
      }
    }
    
    thead {
      th {
        @media (max-width: 768px) {
          .p-3 {
            padding: 0.75rem !important;
          }
          
          .btn {
            font-size: 12px;
            padding: 8px 12px;
          }
        }
      }
    }
  }

  // FAQ section responsive styles
  .accordion {
    .accordion-item {
      font-size: large;
      border-radius: 16px;
      border: 1px solid #13213b;

      @media (max-width: 768px) {
        font-size: medium;
        
        .accordion-button {
          padding: 1rem;
          font-size: 15px;
        }
        
        .accordion-body {
          padding: 1rem;
          font-size: 14px;
        }
      }

      * {
        color: $primary;
      }
    }
  }
  
  // General spacing adjustments
  @media (max-width: 768px) {
    .px-7 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    
    .pb-8 {
      padding-bottom: 3rem !important;
    }
    
    h3 {
      font-size: 1.5rem;
    }
    
    .lead {
      font-size: 1rem;
    }
  }

  // New dedicated button class
  .btn-pricing {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    border: 2px solid #13213b;
    background-color: #13213b;
    color: #FFFFFF;
    transition: all 0.3s ease;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: #FFFFFF;
      border-color: #13213b;
      color: #13213b;
      transform: translateY(-1px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(0);
      box-shadow: none;
    }

    // Responsive styles
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 8px 5px !important;
    }
  }
}