// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

// Note for dark mode:
// Uncomment below code and change primary color code to set different primary(i.e. light color of your primary) color in dark mode only.

/*User CSS*/
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
 --bs-primary: #13213b !important;
 --bs-primary-rgb: 19, 33, 59 !important;
 --bs-link-hover-color:#067799 !important;
 --bs-link-color:#2ac5f5;
 --bs-link-color-rgb: 42, 197, 245 !important;
 --bs-link-hover-color-rgb: 6, 119, 153 !important;
  --bs-secondary: #285eda !important;
  --bs-secondary-rgb: 40, 94, 218 !important;
  --bs-body-color: #12141d !important;

 // Button primary
 .btn-primary {
   --bs-btn-bg: var(--#{$prefix}primary);
   --bs-btn-border-color: var(--#{$prefix}primary);
   --bs-btn-hover-bg: var(--#{$prefix}link-hover-color);
   --bs-btn-hover-border-color: var(--#{$prefix}link-hover-color);
   --bs-btn-active-bg: var(--#{$prefix}link-hover-color);
   --bs-btn-active-border-color: var(--#{$prefix}link-hover-color);
   --bs-btn-disabled-bg: var(--#{$prefix}primary);
 }
 .btn-link {
   --bs-link-color: var(--#{$prefix}primary);
   --bs-btn-hover-color: var(--#{$prefix}link-hover-color);
   --bs-btn-active-color: var(--#{$prefix}link-hover-color);
 }
 .btn-outline-primary {
   --bs-btn-color: var(--#{$prefix}primary);
   --bs-btn-border-color: var(--#{$prefix}primary);
   --bs-btn-hover-bg: var(--#{$prefix}primary);
   --bs-btn-hover-border-color: var(--#{$prefix}primary);
   --bs-btn-active-bg: var(--#{$prefix}primary);
   --bs-btn-active-border-color: var(--#{$prefix}primary);
   --bs-btn-disabled-color: var(--#{$prefix}primary);
   --bs-btn-disabled-border-color: var(--#{$prefix}primary);
 }

 .btn-primary-soft{
   color: var(--#{$prefix}primary);
   background-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
   &:hover, &:focus{
     color: $white;
     background-color: var(--#{$prefix}primary);
     border-color: var(--#{$prefix}primary);
   }
 }

 // Fill color
 .fill-primary{
   fill: var(--#{$prefix}primary) !important;
 }
}

.btn {
  --bs-btn-border-radius: 8px !important;
}

.bg-grey {
  background-color: $grey;
}

.bg-blue-soft {
  background-color: $blue-soft;
}

.bg-grey-blue {
  background-color: unset;
  background-image: linear-gradient(180deg, #1C64F200 0%, #76A9FA26 100%);
}

@import "partials/header";
@import "pages/index";
@import "pages/pricing";

body {
  color: #111928;
  letter-spacing: .1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  font-family: "Inter", serif;
}

h1 {
  font-size: 68px;
  font-weight: 700;
}

h2 {
  font-size: 56px;
  font-weight: 700;
}

h3 {
  font-size: 40px;
  font-weight: 600;
}

h4 {
  font-size: 32px;
  font-weight: 600;
}

h5 {
  font-size: 24px;
  font-weight: 600;
}

section {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.btn-linear {
  background-color: transparent;
  background-image: linear-gradient(260deg, $linear1 40%, $linear2 100%);
  color: white;
  transition: background-image .3s;
  border: none;

  &.btn-linear-light {
    background-image: linear-gradient(260deg, $linear1-light 40%, $linear2-light 100%);
  }

  &.reverse {
    background-image: linear-gradient(180deg, $linear1 0%, $linear2 100%);
  }

  &:hover {
    background-image: linear-gradient(260deg, $linear2 40%, $linear1 100%);
    color: white;

    &.reverse {
      background-image: linear-gradient(180deg, $linear2 0%, $linear1 100%);
    }

    &.btn-linear-light {
      background-image: linear-gradient(260deg, $linear2-light 40%, $linear1-light 100%);
    }
  }
}

.btn-outline-light {
  background: transparent;
  border-color: $secondary;
  color: $secondary;

  &:hover {
    background: $secondary;
    border-color: transparent;
    color: white;
  }
}

.btn-hover-blue {
  &:hover {
    background: $blue;
    border-color: transparent !important;
    color: white;
  }
}

.blue-bloc {
  background: #ebf5ff;
  border: 1px solid #3f83f8;
}

.flex-1 {
  flex: 1;
}

.text-blue-dark {
  color: $dark-blue !important;
}

.text-blue-alternate {
  color: $blue-alternate !important;
}

.ff-outfit {
  font-family: "Outfit", serif !important;
}

.ff-inter {
  font-family: "Inter", serif !important;
}

.bi-chevron-right {
  -webkit-text-stroke-width: 2px;
  font-size: 11px;
}

.fs-6 {
  font-size: 20px !important;
}

.text-blue-fade {
  background: var(--gradient, linear-gradient(90deg, $fade-1 0, $fade-2 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-grey {
  border-color: $border-grey !important;
}

// New general button class
.btn-primary-new {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  border: 2px solid $primary;
  background-color: $primary;
  color: #FFFFFF;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: rgba($blue, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.6s ease-out, height 0.6s ease-out;
    z-index: -1;
  }

  &:hover {
    color: #FFFFFF;
    border-color: $blue;
    background-color: $blue;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);

    &:before {
      width: 300px;
      height: 300px;
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  // Optional: outline variant
  &.outline {
    background-color: transparent;
    color: $primary;

    &:hover {
      color: #FFFFFF;
    }
  }

  // Optional: size variants
  &.lg {
    padding: 16px 32px;
    font-size: 18px;
  }

  &.sm {
    padding: 8px 16px;
    font-size: 14px;
  }

  // Responsive styles
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }
}